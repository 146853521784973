import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { trackCartOpen } from 'events/index'
import { RootStore } from 'stores'
import { NavBar } from 'components/NavBar/NavBar'
import { FC } from 'react'

interface Props {
	any?: string
	rootStore?: RootStore
}

const HeaderComponent: FC<Props> = (props) => {
	const openCart = () => {
		trackCartOpen()
		props.rootStore.cartStoreNew.toggleCartOpen()
	}

	const { settingsStore, promoStore, cartStoreNew } = props.rootStore
	const { currentPage, currentRegion } = props.rootStore.settingsStore
	const endDate = settingsStore.saleEndDate
	const discountCode = promoStore.discountCode

	const promoMessage = promoStore.promoBarTitle + (settingsStore.settings.partnerName ? ` with code <strong>${settingsStore.settings.promoCode.toUpperCase()}</strong>` : '')
	const promoLink = promoStore.promoBarLink

	const activeTimer = settingsStore.settings.activeTimer
	const showTimer = promoStore.showTimer

	let path = currentPage.split('?')[0].replace(`/${currentRegion}`, '')
	if (!path.endsWith('/')) {
		path = `${path}/`
	}
	if (!path.startsWith('/')) {
		path = `/${path}`
	}

	const isCoverLP = path === '/pod-cover/'
	const isProspecting = path === '/pod-prospecting/'
	const isVanity = settingsStore.isVanity

	const handleLocalize = currentRegion === 'eu' && typeof window !== 'undefined'

	return (
		<NavBar
			promo_bar_text={promoMessage}
			promo_bar_href={promoLink}
			promo_bar_date={endDate}
			region={currentRegion}
			cartClick={openCart}
			numberOfCartItems={cartStoreNew.numItems}
			ctaVisible={isProspecting || path === '/' || path === '/wall-of-love/' || path === '/pod-prospecting/'}
			ctaLabel={'Shop now'}
			activeTimer={activeTimer}
			showTimer={showTimer}
			excludeLinks={discountCode === 'members' || isProspecting}
			onlyLogo={isProspecting}
			ctaAnchorHref={isProspecting ? '#shop' : null}
			transparency={settingsStore.navBarTransparency}
			isCoverLPAB={isCoverLP}
			isVanity={isVanity}
			saleName={settingsStore.settings.saleName}
			onPickerClick={
				handleLocalize
					? () => {
							props.rootStore.modalStore.toggleLocalizeModal()
					  }
					: undefined
			}
		/>
	)
}

export const Header = inject('rootStore')(observer(HeaderComponent))

export default Header
